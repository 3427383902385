import {
    Alert,
    Box,
    Button,
    DropdownSelect,
    StandardPage,
    TextInput
} from "../../common";
import {Form, Formik} from "formik";
import styles from "./AddAccount.module.scss";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import Loading from "../../Loading/Loading";
import SearchableList from "../../common/_SearchableList";
import StandardText from "../../common/_StandardText";
import {CreateCustomer, SelectCustomer} from "../../AccountDetails/CustomerSummary/EditCustomer/EditCustomer";
import {useIntl} from "react-intl";
import {parseUTCDateString} from "../../Helpers/DateTimeHelpers";
import * as Yup from "yup";
import {locations} from "../../../utils/constants"

const getMailhouses = (setMailhouses, setLoadingMailhouses) => {
    setLoadingMailhouses(true);
    axios.get("/api/payreq-accounts/mailhouses")
        .then(({data}) => {
            data = data.filter((mailhouse) => mailhouse.status !== 1);
            setMailhouses(data.map((mailhouse) => [mailhouse.id, mailhouse.id]).sort());
        })
        .finally(() => {
            setLoadingMailhouses(false);
        });
}

const getTimezones = (setTimezones, setLoadingTimezones) => {
    setLoadingTimezones(true);
    axios.get("/api/payreq-accounts/timezones")
        .then(({data}) => {
            setTimezones(data.map((timezone) => timezone.name));
        })
        .finally(() => {
            setLoadingTimezones(false);
        });
};

const getTags = (setTags, setLoadingTags) => {
    setLoadingTags(true);
    axios.get("/api/payreq-accounts/tags")
        .then(({data}) => {
            setTags(data);
        })
        .finally(() => {
            setLoadingTags(false);
        });
}

const getFreePackId = (setFreePackId, setLoadingFreePackId) => {
    setLoadingFreePackId(true);
    axios.get("/api/credits/prepaid-packs")
        .then(({data}) => {
            setFreePackId(data.find((pack) => pack.name === "Prepay 5 paystubs").id)
        })
        .finally(() => {
            setLoadingFreePackId(false);
        });
}

const endOfTime = "9999-12-31";
const calcExpiry = (plan) => {
    const postpay = 1;
    if (plan === postpay) {
        return parseUTCDateString(endOfTime);
    } else {
        let six = new Date(Date.now());
        six = new Date(six.setMonth(six.getMonth()+6)).toISOString();
        return six;
    }
}

const tagTaken = (tags, values) => {
    return (tags.find(tag => tag.tag === values.billercode) && true) || false;
}

const makeEdit = (values, tags, setSubmitting, packId) => {
    const biller = 1;
    const canadaId = locations.ca;
    const pack = (values.plan === "1") ? 1 : packId;

    if (tagTaken(tags, values)) {
        setSubmitting(false);
        return Promise.resolve();
    }

    if (values.createCustomer) {
        return axios.post("/api/credits/mailer", {
            country: parseInt(values.country),
            integrator: values.integrator ? values.integrator : null,
            location: (values.mailertype === biller) ? parseInt(values.location) : canadaId,
            logo: values.logo,
            mailerName: values.mailername,
            billerCode: values.billercode,
            name: values.name,
            packId: pack,
            expiry: calcExpiry(values.plan),
            state: parseInt(values.state),
            timezone: values.timezone,
            mailerType: parseInt(values.mailertype)})
    } else {
        return axios.post("/api/payreq-accounts/mailer", {
            customer: values.customer,
            integrator: values.integrator ? values.integrator : null,
            location: (values.mailertype === biller) ? parseInt(values.location) : canadaId,
            logo: values.logo,
            mailerName: values.mailername,
            billerCode: values.billercode,
            timezone: values.timezone,
            mailerType: parseInt(values.mailertype)
        })
    }
}

const AddMailer = () => {
    const history = useHistory();
    const id = parseInt(useParams().id);
    const [mailhouses, setMailhouses] = useState([])
    const [timezones, setTimezones] = useState(true);
    const [tags, setTags] = useState([]);
    const [freePackId, setFreePackId] = useState(null);
    const [loadingTimezones, setLoadingTimezones] = useState(true);
    const [loadingMailhouses, setLoadingMailhouses] = useState(false);
    const [loadingTagNames, setLoadingTags] = useState(false);
    const [loadingFreePackId, setLoadingFreePackId] = useState(false);
    const locations = [["mailer.location.au", 1], ["mailer.location.ca", 2], ["mailer.location.uk", 3],
                        ["mailer.location.nz", 4]]
    // 0 is existing, 1 is new
    const [customerCreate, setCustomerCreate] = useState(false);
    const intl = useIntl();
    const types = [["mailer.biller", 1], ["mailer.payroll", 2]];
    const prepaid = 22;
    const biller = 1;

    useEffect(() => getMailhouses(setMailhouses, setLoadingMailhouses),
        [id]);

    useEffect(() => getTimezones(setTimezones, setLoadingTimezones),
        [id]);

    useEffect(() => getTags(setTags, setLoadingTags),
        [id]);

    useEffect(() => getFreePackId(setFreePackId, setLoadingFreePackId),
        [id]);

    if (loadingMailhouses || loadingTimezones || loadingTagNames || loadingFreePackId) {
        return <Loading/>
    }

    const validationSchema = Yup.object().shape({
        mailername: Yup.string().required("accounts.add.name.warning"),
        billercode: Yup.string().required("accounts.add.billercode.warning.empty"),
        timezone: Yup.string().required("accounts.add.timezone.warning"),
        customer: Yup.string().when('createCustomer', {
            is: (createCustomer) => createCustomer === false,
            then: Yup.string().required("accounts.add.customer.warning"),
        }),
        name: Yup.string().when('createCustomer', {
            is: (createCustomer) => createCustomer === true,
            then: Yup.string().required("accounts.add.customer.warning"),
        }),
    })

    return (
        <StandardPage header={"accounts.add.header"} parentPath={"/accounts"}>
            <Box>
                <Formik
                    initialValues={{
                        mailername: "",
                        billercode: "",
                        name: "",
                        customer: "",
                        country: "",
                        state: "",
                        location: "1",
                        timezone: "Australia/Sydney",
                        logo: "",
                        integrator: "",
                        search: "",
                        plan: prepaid,
                        createCustomer: false,
                        mailertype: biller
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        makeEdit(values, tags, setSubmitting, freePackId)
                            .finally(()=>{
                                setSubmitting(false)
                                history.replace("/accounts/")
                            });
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={styles.entryFields}>
                                <TextInput id="mailername"
                                           className={styles.firstInput}
                                           label={"accounts.add.name"}
                                           placeholder={"accounts.add.name.placeholder"}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.mailername}
                                           errors={errors.mailername}
                                           touched={touched.mailername}/>

                                <TextInput id="billercode"
                                           className={styles.firstInput}
                                           label="accounts.add.billercode"
                                           placeholder="accounts.add.billercode.placeholder"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.billercode}
                                           errors={errors.billercode}
                                           touched={touched.billercode}/>

                                {tagTaken(tags, values) &&
                                    <Alert variant={"danger"}><StandardText text={"accounts.add.tag.warning"}/></Alert>}

                                <DropdownSelect id="mailertype"
                                                className={styles.dropdown}
                                                label={"accounts.add.mailertype"}
                                                name={`mailertype`}
                                                options={types}
                                                value={values.mailertype}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errors={errors.mailertype}
                                                touched={touched.mailertype}/>

                                <DropdownSelect id="location"
                                                className={styles.dropdown}
                                                label={"accounts.add.location"}
                                                name={`location`}
                                                options={(values.mailertype === 1) ? locations : [locations[1]]}
                                                value={values.location}
                                                onChange={(e) => {
                                                    setFieldValue('location', e.target.value);
                                                }}
                                                onBlur={handleBlur}
                                                errors={errors.location}
                                                touched={touched.location}/>

                                {!loadingTimezones &&
                                    <SearchableList
                                        id="timezone" label={"accounts.add.timezone"} name={`timezone`}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                        setFieldValue={setFieldValue}
                                        value={values.timezone} onBlur={handleBlur}
                                        errors={errors.timezone} touched={touched.timezone}
                                        list={timezones}
                                        onKeyPress={(e) => {e.key === 'Enter' && e.preventDefault()}}
                                    />
                                }

                                <TextInput id="logo"
                                           label={"accounts.add.logo"}
                                           placeholder={"accounts.add.logo.placeholder"}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.logo}
                                           errors={errors.logo}
                                           touched={touched.logo}
                                />

                                <DropdownSelect id="integrator"
                                                options={mailhouses}
                                                className={styles.dropdown}
                                                label={"accounts.add.integrator"}
                                                name={`integrator`}
                                                value={values.integrator}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errors={errors.integrator}
                                                touched={touched.integrator}
                                                labelFn={x => x}/>

                                { !customerCreate ?
                                    <>
                                        <b><StandardText text={"accounts.add.customer"}/></b>
                                        <SelectCustomer values={values} setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange} intl={intl} history={history}
                                        />
                                    </>
                                    :
                                    <>
                                        <b><StandardText text={"accounts.add.customer.title"}/></b>
                                        <div className={styles.newCustomer}>
                                            <CreateCustomer
                                                values={values} errors={errors} handleChange={handleChange}
                                                touched={touched}
                                                handleBlur={handleBlur}/>
                                        </div>
                                    </>
                                }

                                {!loadingTimezones &&
                                    <div className={styles.customerChange}
                                         onClick={(e) => {
                                            if (e.nativeEvent.pointerType !== "") {
                                                setCustomerCreate(!customerCreate)
                                                setFieldValue('createCustomer', !customerCreate);
                                            }
                                         }}>
                                        {intl.formatMessage({id: customerCreate ? "accounts.add.customer.existing" : "accounts.add.customer.new"})}
                                    </div>}
                            </div>

                            <Button className={styles.buttons} label={"accounts.add.save"} variant={"primary"} loading={isSubmitting}
                                    type="submit"/>
                            <Button className={styles.buttons} label={"accounts.add.cancel"} variant={"secondary"} onClick={() => history.push("/accounts")} type="button"/>
                        </Form>
                    )}
                </Formik>
            </Box>
        </StandardPage>
    )
}

export default AddMailer
import {Formik} from "formik";
import axios from "axios";
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {useAppState} from "../../state";
import {SET_USER} from "../../state/reducers/userReducer";
import {Alert, Button, Form, PrimaryHeading, SecondaryHeading, StandardText, TextInput} from "../common";
import React, {useState} from "react";
import styles from "./Login.module.scss";
import logo from "../../resources/Images/Atlas Favicon 2.0 (1).png"

const login = (values, setSubmitting, history, dispatch, setHasError) => {
    axios.post("/api/login", values)
        .then(({data: {success, user}}) => {
            if (success) {
                dispatch({
                    type: SET_USER,
                    user: user
                })
                getCsrfToken(() => {})
                history.push("/")
            } else {
                setHasError(true)
            }
        })
        .finally(() => setSubmitting(false));
};

export const getCsrfToken = (onCompleted) => {
    axios.get("/api/csrf", {headers: {"Cache-Control": "no-store"}})
        .then(({data: {token}}) => {
            if (token) {
                axios.defaults.headers.post["X-CSRF-Token"] = token;
                axios.defaults.headers.put["X-CSRF-Token"] = token;
                axios.defaults.headers.delete["X-CSRF-Token"] = token;
            }
            onCompleted()
        });
};

const LoginSchema = Yup.object().shape({
    email: Yup.string().required("login.emailRequired"),
    password: Yup.string().required("login.passwordRequired"),
    mfaCode: Yup.string().required("login.mfaRequired"),
});

const Login = () => {
    const history = useHistory()
    const [, dispatch] = useAppState();
    const [hasError, setHasError] = useState(false);

    return (
        <div className={styles.loginPage}>
            <div className={styles.logoText}>
                <PrimaryHeading text="login.header" className={styles.loginHeader}/>
                <img src={logo} className={styles.atlasLogo} alt={"logo"}/>
            </div>

            <SecondaryHeading text="login.subheader" className={styles.loginSubHeader}/>
            <div className={styles.loginBox}>
                <SecondaryHeading text="login.loginBoxHeader" className={styles.boxHeader}/>
                <Formik
                    initialValues={{email: '', password: '', mfaCode: ''}}
                    onSubmit={(values, {setSubmitting}) => login(values, setSubmitting, history, dispatch, setHasError)}
                    validationSchema={LoginSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit} width={12}>
                            <TextInput id="email"
                                       type="text"
                                       placeholder={"login.emailPlaceholder"}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.email}
                                       errors={errors.email || hasError}
                                       touched={touched.email}
                            />
                            <TextInput id="password"
                                       type="password"
                                       placeholder={"login.passwordPlaceholder"}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.password}
                                       errors={errors.password || hasError}
                                       touched={touched.password}
                            />
                            <TextInput id="mfaCode"
                                       type="number"
                                       placeholder={"login.mfaPlaceholder"}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.mfaCode}
                                       errors={errors.mfaCode || hasError}
                                       touched={touched.mfaCode}
                                       autoComplete="off"
                            />
                            <Button label={"login.loginButton"} className={styles.loginButton} loading={isSubmitting}/>
                            {hasError && (
                                <Alert style={{marginTop: "1rem"}} variant="danger"><StandardText text="login.badLogin"/></Alert>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>

        </div>
    )
}

export default Login;
